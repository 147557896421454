import React from "react";
import logo1 from "../img/icon.png";

import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { Collapse } from "bootstrap";

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      logo: logo1,
      height: 66,
    };
  }

  redirectLink() {
    setTimeout(() => {
      const navbarToggle = document.getElementById("navbar-toggler");
      const width = document.body.clientWidth;

      if (navbarToggle !== null && width <= 768) {
        navbarToggle.click();
      }
    }, 0);
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        document
          .querySelector(".navbar-expand-md")
          .classList.add("navbar-reduce");
        document
          .querySelector(".navbar-expand-md")
          .classList.remove("navbar-trans");
        this.setState({ logo: logo1 });
      } else {
        document
          .querySelector(".navbar-expand-md")
          .classList.add("navbar-trans");
        document
          .querySelector(".navbar-expand-md")
          .classList.remove("navbar-reduce");
        this.setState({ logo: logo1 });
      }
    });

    if (window.innerHeight > window.innerWidth) {
      this.setState({ height: 10 });
    }
  }

  render() {
    return (
      <nav
        className="navbar navbar-b navbar-trans navbar-expand-md fixed-top"
        id="mainNav"
      >
        <button
          className="navbar-toggler collapsed"
          id="navbar-toggler"
          type="button"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
          data-bs-toggle="collapse"
          data-bs-target="#navbarDefault"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div className="navbar-collapse collapse" id="navbarDefault">
          <div class="w-100 brand">
            <a class="navbar-brand" href="#">
              <img
                src={logo1}
                width="40"
                class="d-inline-block align-text-top"
                id="logo-navbar"
                alt="Logo"
              />
              Drobbe
            </a>
          </div>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link link-primary"
                activeClass="active"
                to="home"
                offset={-this.state.height}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => this.redirectLink()}
              >
                Inicio
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link link-primary"
                activeClass="active"
                to="about-container"
                offset={-this.state.height}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => this.redirectLink()}
              >
                Acerca
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link link-primary"
                activeClass="active"
                to="work-container"
                offset={-this.state.height}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => this.redirectLink()}
              >
                Experiencia
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link link-primary"
                activeClass="active"
                to="contact-container"
                offset={-this.state.height}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => this.redirectLink()}
              >
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
