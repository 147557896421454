import React from "react";

class Preloader extends React.Component {
  constructor() {
    super();
    this.state = { topPercentage: "", rightPercentage: "", brithness: 0 };
    this.positions = [];
  }

  componentDidMount() {
    this.setState({
      topPercentage: 0,
      // rightPercentage: 50,
    });
  }

  tick(value) {
    this.positions.push(value);
    setTimeout(() => {
      this.positions.shift();
      if (this.positions.length === 0)
        this.setState({
          topPercentage: value + 10 / 2,
          rightPercentage: value,
        });
    }, 200);
  }

  render() {
    return (
      <>
        <svg
          version="1.1"
          id="moon"
          viewBox="0 0 50 50"
          fill="white"
          className="moon"
        >
          <path d="M46.369,28.793c-11.852,5.935-26.271,1.138-32.206-10.714c-2.748-5.488-3.191-11.524-1.702-17.016  C1.197,7.236-3.255,21.263,2.544,32.844C8.479,44.696,22.898,49.493,34.75,43.558c6.364-3.187,10.69-8.821,12.417-15.19  C46.903,28.513,46.64,28.658,46.369,28.793z" />
        </svg>
        {/* <img
          alt="Mi SVG feliz"
          className="mountains"
          src="https://i.imgur.com/wJTMhRl.png"
          style={{
            filter: `brightness(${this.state.brithness})`,
          }}
        ></img> */}
      </>
    );
  }
}

export default Preloader;
