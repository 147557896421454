import React from "react";
import photo from "../img/mt.png";
import { useInView } from "react-intersection-observer";

function Contact() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <section className="paralax-mf bg-image sect-mt4 route" id="contact">
      <div className="overlay-mf dawn-background"></div>
      <div className={"container "} id="contact-container">
        <div className="row justify-content-md-center">
          <div className="col col-lg-8 ">
            <div ref={ref} className={"box " + (inView ? "bounce-up" : "")}>
              <div className="title-box-2 pt-4 pt-md-0">
                <h5 className="title-left">Contacta Conmigo</h5>
              </div>
              <div className="more-info">
                <ul className="list-ico">
                  <li>
                    <i className="fa-solid fa-location-pin color-primary pe-2 "></i>
                    <p className="paragraph">Alcorcon, Madrid España</p>
                  </li>
                  <li>
                    <i className="fa-solid fa-phone color-primary pe-2"></i>
                    <p className="paragraph">(+34) 643216057</p>
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope color-primary pe-2"></i>
                    <a
                      href="mailto:edgard1394@gmail.com"
                      style={{ textDecoration: "none" }}
                    >
                      <p className="paragraph">edgard1394@gmail.com</p>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="socials">
                <div
                  class="btn-group btn-group-lg"
                  role="group"
                  aria-label="Basic example"
                >
                  <a
                    type="button"
                    class="btn btn-secondary border border-white"
                    href="https://api.whatsapp.com/send?phone=34643216057&text=%20%F0%9F%91%8B%20Hola%20Edgard%20Rojas%2C%20quisiera%20contactar%20contigo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-whatsapp fa-xl "></i>
                  </a>
                  <a
                    type="button"
                    class="btn btn-secondary border border-white"
                    href="https://github.com/drobbe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-github fa-xl "></i>
                  </a>
                  <a
                    type="button"
                    class="btn btn-secondary border border-white"
                    href="https://www.linkedin.com/in/edgard-andres-rojas-mujica-38a789142/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin fa-xl "></i>
                  </a>
                </div>
              </div>

              <div className="title-box-2">
                <h5 className="title-left">Enviar Un Mensaje</h5>
              </div>

              <div>
                <form
                  action="https://formbold.com/s/3qrmo"
                  method="POST"
                  className="contactForm"
                >
                  <div id="sendmessage">
                    ¡El mensaje fue enviado muchas gracias!
                  </div>
                  <div id="errormessage"></div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-transparent"
                          id="name"
                          placeholder="Nombre"
                          data-rule="minlen:4"
                          data-msg="Please enter at least 4 chars"
                        />
                        <div className="validation"></div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-transparent"
                          name="email"
                          id="email"
                          placeholder="Email"
                          data-rule="email"
                          data-msg="Please enter a valid email"
                        />
                        <div className="validation"></div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-transparent"
                          name="subject"
                          id="subject"
                          placeholder="Asunto"
                          data-rule="minlen:4"
                          data-msg="Please enter at least 8 chars of subject"
                        />
                        <div className="validation"></div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <textarea
                          className="form-transparent"
                          name="message"
                          rows="4"
                          data-rule="required"
                          data-msg="Please write something for us"
                          placeholder="Mensaje"
                        ></textarea>
                        <div className="validation"></div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-primary px-4">
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img alt="Mi SVG feliz" className="mountains" src={photo}></img>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box">Hecho por Edgard Rojas 🌙</div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Contact;
