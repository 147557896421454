import React from 'react';
import ReactDOM from 'react-dom';
import './fonts/nasalization-rg.otf';

//import css in order
// import 'normalize.css';
// import './animate.css';
// import 'bootstrap/dist/css/bootstrap.css';
// import './img/font-awesome/css/font-awesome.css';
// import 'lightbox2/dist/css/lightbox.min.css';
import './theme.scss';
import './style.scss';

//import js libraries

// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import './libs/easing.js';
// import 'lightbox2/dist/js/lightbox.min.js';

import * as serviceWorker from './serviceWorker';

//import components
import Navbar from './components/navbar.jsx';
import Intro from './components/intro.jsx';
import About from './components/about.jsx';
// import Portfolio from './components/portfolio.jsx';
import Contact from './components/contact.jsx';
import Work from './components/work.jsx';
import BackToTop from './components/back-top.jsx';
import Preloader from './components/preloader';
import Footer from './components/footer';

function renderCallback(e) {
  const preload = document.getElementById('preload');
  preload.classList.add('fade-out');
  setTimeout(() => {
    preload.remove();
  }, 500);
}

setTimeout(() => {
  ReactDOM.render(
    <React.Fragment>
      <Navbar />
      <Intro />
      <About />

      <Work />
      <Contact />
      <BackToTop />
      <Preloader />
    </React.Fragment>,
    document.getElementById('root'),
    renderCallback
  );
}, 800);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
