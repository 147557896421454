import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "../styles/helper.scss";
const technologies = {
  experience: [
    {
      company: "WhiteTeam",
      title: "Frontend Developer",
      years: "12/2022 - presente",
      mainTech: ["Angular 8/9/10"],
      icon: "fa-brands fa-sass fa-2xl",
      technologies: ["Angular", "Reactjs", "Sass", "RxJS", "Redux", "Tailwind"],
      activities: [
        "Desarrollo de interfaces de usuario (Tailwind, Sass).",
        "Optimización de rendimiento de las aplicaciones web.",
        "Asegurar Diseño responsivo de la aplicaciones.",
        "Interactuar con servicios web y utilizar APIs (React, Angular).",
        "Colaborar con diseñadores y equipos de desarrollo para transformar diseños y mockups en aplicaciones funcionales.",
      ],
    },
    {
      company: "Tismtart",
      title: "Fullstack Developer",
      years: "08/2021 - 11/2022",
      mainTech: ["Angular 8/9/10"],
      icon: "fa-brands fa-angular fa-2xl",
      technologies: [
        "C#",
        ".NET Core",
        "REST API",
        "RxJS",
        "JavaScript",
        "TypeScript",
        "Kafka",
        "Bootstrap",
        "Angular Material",
        "NestJs",
      ],
      activities: [
        "Migración de sistemas legacies a nueva tecnologías. (.Net , Nodejs)",
        "Optimizaciones de Procedimientos almacenados y Querys Construcción y modificación de microservicios.",
        "Evaluar, identificar y corregir errores en software existente Analizar software para futuras actualizaciones y mejoras.",
        "Integración con diversos servicios en la nubes (GCP, Azure).",
      ],
    },
    {
      company: "On Bot Go!",
      title: "Software Developer",
      years: "12/2019 - 07/2021",
      mainTech: ["Angular 7/8"],
      icon: "fa-brands fa-node-js fa-2xl",
      technologies: [
        "RxJS",
        "Django",
        "PHP",
        "JavaScript",
        "NodeJs",
        "NextJs",
        "GCP",
      ],
      activities: [
        "Desarrollo de Aplicaciones FrontEnd/BackEnd bajo tecnologías como (PHP, Pyhton, NodeJs y  Angular)",
        "Migración a una arquitectura bajo microservicios.",
        "Capacitaciones a usuarios acerca de nuevas funcionalidades de los sistema.",
        "Creación de bot con speech analytics  (Asterisk).",
        "Implementación de soluciones en la nube (GCP).",
      ],
    },
    {
      company: "Mipto Tech, C.A",
      title: "Web Developer",
      years: "01/2018 - 09/2019",
      mainTech: ["Angular 7/8"],
      icon: "fa-brands fa-aws fa-xl",
      technologies: [
        "NodeJS",
        "Ionic",
        "Angular",
        "TypeScript",
        "Python",
        "AWS",
        "RabbitMq",
      ],
      activities: [
        "Uso de los servicios (AWS) como S3, Bucket, Pipeline, Lamba, DynamoDb entre otras.",
        "Implementación de la infraestructura de (Firebase) usando sus distintas herramientas.",
        "Programación de aplicaciones móviles híbridas (Ionic).",
        "Desarrollos de Api Restfull bajo el stack MEAN.",
      ],
    },
    {
      company: "Integra Servicios Médicos",
      title: "Junior Developer",
      years: "06/2016 - 10/2017",
      mainTech: ["Angular 7/8"],
      icon: "fa-brands fa-php fa-2xl",
      technologies: ["PHP", "Java", "JasperReport", "Jquery", "PostgreSql"],
      activities: [
        "Desarrollo de módulos de facturación, reportes y orden del sistema.",
        "Creación y configuración de reportes a través de la herramienta JasperReports.",
        "Mantener y actualizar los sistemas existentes (Java).",
        "Elaboración de querys, vistas y store procedures en (PostgreSQL).",
      ],
    },
  ],
};

class Work extends Component {
  render() {
    const work = technologies.experience.map(function (work, i) {
      const technologies = work.technologies;
      const icon = `${work.icon} icon-time-line`;
      const activities = work.activities.map((activity) => (
        <p className="lead">
          <i className="fa-solid fa-square fa-xs color-primary px-2"></i>
          {activity}
        </p>
      ));

      let tech = technologies.map((technology, i) => {
        return <span className="badge mx-1">{technology}</span>;
      });
      return (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          style={{ zIndex: 2 }}
          date={work.years}
          iconStyle={{
            background: "#7757ff",
            color: "#fff",
            textAlign: "center",
            margin: "3px",
            borderRadius: "3px",
          }}
          icon={<i className={icon}></i>}
          key={i}
        >
          <h3 className="title-left">{work.title}</h3>
          <h4
            className="vertical-timeline-element-subtitle text-primary"
            style={{ textAlign: "left" }}
          >
            {work.company}
          </h4>

          {activities}
          <div style={{ textAlign: "left", marginTop: "15px" }}>{tech}</div>
        </VerticalTimelineElement>
      );
    });

    return (
      <section className="paralax-mf bg-image ">
        <div className="overlay-mf morning-background"></div>
        <div className="container" id="work-container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-8">
              {/* <h1 className="mb-4 text-center sticky color-white">Experiencia</h1> */}
              <VerticalTimeline
                lineColor="#7757ff"
                layout="1-column-left"
                className={"timeline"}
              >
                {work}
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Work;
