import React, { useState } from "react";

import { useInView } from "react-intersection-observer";

const hoy = new Date();
const yearsWorking = hoy.getFullYear() - 2016;
const data = {
  skills: [
    { id: "HTML5_skill", content: "HTML5", porcentage: "95%", value: "95" },
    { id: "CSS3_skill", content: "CSS3", porcentage: "85%", value: "85" },
    {
      id: "Node_skill",
      content: "NodeJS",
      porcentage: "90%",
      value: "90",
    },
    { id: "PHP_skill", content: "PHP", porcentage: "70%", value: "70" },
    {
      id: "ReactJS_skill",
      content: "ReactJS",
      porcentage: "80%",
      value: "80",
    },
    {
      id: "Python_skill",
      content: "Python",
      porcentage: "50%",
      value: "50",
    },
    {
      id: "Angular_skill",
      content: "Angular",
      porcentage: "80%",
      value: "80",
    },
    {
      id: "React_skill",
      content: "React",
      porcentage: "70%",
      value: "70",
    },
    {
      id: "TypeScript_skill",
      content: "TypeScript",
      porcentage: "80%",
      value: "80",
    },
    {
      id: "SQL_skill",
      content: "SQL",
      porcentage: "90%",
      value: "90",
    },
    {
      id: "Mongo_skill",
      content: "Mongo",
      porcentage: "90%",
      value: "90",
    },
    {
      id: "Cloud_skill",
      content: "AWS/GCP",
      porcentage: "60%",
      value: "60",
    },
    {
      id: "Microserives_skill",
      content: "Microservices",
      porcentage: "60%",
      value: "60",
    },
  ],
  about_me: [
    {
      id: "first-p-about",
      content:
        "Soy un apasionado y experimentado Senior Programador Fullstack con amplia experiencia en el desarrollo de aplicaciones web y soluciones tecnológicas. Mi enfoque se centra en la creación de productos de alta calidad que combinen funcionalidad y diseño.",
    },
    {
      id: "second-p-about",
      content:
        "A lo largo de mi carrera, he trabajado en diferentes industrias, con más de 7 años de experiencia en el campo. Mi experiencia abarca el desarrollo de aplicaciones tanto en el lado del cliente como en el del servidor, trabajando con una variedad de lenguajes de programación, lo que me permite adaptarme rápidamente a nuevas tecnologías y mantenerme actualizado con las últimas tendencias del desarrollo web.",
    },
  ],
};

function About() {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <section id="about" className="about-mf paralax-mf bg-image">
      <div className="overlay-mf evening-background"></div>
      <div className="container" id="about-container">
        <div className="row">
          <div className="col-sm-12">
            <div ref={ref} className={"box " + (inView ? "bounce-up" : "")}>
              <div className="row">
                <div className="col-md-12">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">Acerca de mi</h5>
                    </div>
                    {data.about_me.map((content) => {
                      return (
                        <p className="lead" key={content.id}>
                          {content.content}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="title-box-2">
                      <h5 className="title-left">Skills</h5>
                    </div>
                  </div>
                  <div className="skill-mf">
                    {data.skills.map((skill) => {
                      return (
                        <React.Fragment key={skill.id}>
                          <span>{skill.content}</span>{" "}
                          <span className="pull-right">{skill.porcentage}</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: inView ? skill.porcentage : "0%",
                              }}
                              aria-valuenow={skill.value}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
