import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box">Hecho por Edgard Rojas 🌙</div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
