import React from 'react';
import Typed from 'typed.js';

import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const particlesInit = async (main) => {
  await loadFull(main);
};

const particlesLoaded = (container) => {};

class TypedReact extends React.Component {
  componentDidMount() {
    // If you want to pass more options as props, simply add
    // your desired props to this destructuring assignment.
    const { strings } = this.props;
    // You can pass other options here, such as typing speed, back speed, etc.
    const options = {
      strings: strings,
      typeSpeed: 10,
      backSpeed: 50,
      backDelay: 1100,
    };
    // this.el refers to the <span> in the render() method
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.typed.destroy();
  }

  render() {
    return (
      <div className="wrap">
        <div className="type-wrap">
          <span
            style={{ whiteSpace: 'pre' }}
            ref={(el) => {
              this.el = el;
            }}
          />
        </div>
      </div>
    );
  }
}

class Intro extends React.Component {
  componentDidMount() {
    // If you want to pass more options as props, simply add
    // your desired props to this destructuring assignment.
    const { strings } = this.props;
    // You can pass other options here, such as typing speed, back speed, etc.

    // this.el refers to the <span> in the render() method
    this.typed = new TypedReact(this.el);
  }

  render() {
    return (
      // <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
      <section>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: '#000',
              },
            },
            fullScreen: true,
            fpsLimit: 120,
            interactivity: {
              events: {
                // onClick: {
                //   enable: true,
                //   mode: 'push',
                // },
                onHover: {
                  enable: true,
                  mode: 'slow',
                  parallax: {
                    enable: true,
                    force: 60,
                    smooth: true,
                  },
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 50,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: '#ffffff',
              },
              // links: {
              //   color: '#7b67d6',
              //   distance: 150,
              //   enable: true,
              //   opacity: 0.5,
              //   width: 1,
              // },
              collisions: {
                enable: true,
              },
              // move: {
              //   direction: 'none',
              //   enable: true,
              //   outModes: {
              //     default: 'bounce',
              //   },
              //   random: false,
              //   speed: 1,
              //   straight: false,
              // },
              number: {
                density: {
                  enable: true,
                  area: 300,
                },
                value: 80,
              },
              opacity: {
                animation: {},
                value: {
                  min: 0.5,
                  max: 1,
                },
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: { min: 0, max: 1.5 },
              },
            },
            detectRetina: true,
          }}
        />

        <div id="home" className="intro route bg-image background">
          <div className="intro-content display-table">
            <div className="table-cell">
              <div className="container">
                <h1 className="intro-title mb-4">
                  <span className="hello">¡Hola!, Soy Edgard Rojas </span>
                </h1>

                <p className="intro-subtitle">
                  <span className="text-slider-items"></span>
                  <strong className="text-slider">
                    <TypedReact
                      strings={[
                        'Back End Developer',
                        'Front End Developer',
                        'Software Engineer',
                      ]}
                      typeSpeed={200}
                      backDelay={1100}
                      backSpeed={30}
                      loop
                    />
                  </strong>
                </p>
                <p className="pt-3">
                  <a
                    className="btn btn-primary btn js-scroll px-4"
                    href="#work"
                    role="button"
                  >
                    Ver mi trabajo
                  </a>
                </p>

                <Player
                  autoplay
                  loop
                  src="https://assets-v2.lottiefiles.com/a/201beb62-1189-11ee-b914-4368693c2fcc/A5NtwnOgG9.json"
                  style={{ height: '300px', width: '300px' }}
                >
                  <Controls
                    visible={false}
                    buttons={['play', 'repeat', 'frame', 'debug']}
                  />
                </Player>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Intro;
